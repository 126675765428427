'use client'
import Link from "next/link";
import styles from "./NotFound.module.scss"
 
export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <html>
      <body>
        <h2>La página que solicita no está disponible</h2>
        <button onClick={() => reset()}>Intentar nuevamente</button>
        <Link href="/">
            <button className={styles.button}>Ir a la página principal</button>
        </Link>
      </body>
    </html>
  )
}